.device-name-field-container {
  display: flex;
  align-items: center;
  column-gap: 20px;

  .device-name-field {
    width: calc(100% - 65px);
  }
}

.open-color-picker {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.support__url-btn {
  font-weight: 400;
  color: var(--primary-color);
  font-size: 14px;
  line-height: 15px;
  text-decoration: none;
  margin-right: 3px;
  padding: 0;
  border: none;
  background-color: transparent;

  .question-mark-icon {
    display: inline-block;
    text-align: center;
    width: 16px;
    height: 16px;
    border: 1px solid var(--primary-color);
    border-radius: 50%;
  }

  &:hover {
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    filter: brightness(0.5);

    &:hover {
      text-decoration: none;
    }
  }
}