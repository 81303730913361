.switching-times__container {
  display: flex;
  flex-direction: column;

  .switching-times__collapse-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    .switching-times__empty-list {
      text-align: center;
      color: #fff;
      padding: 10px;
    }
  }

  .customTextField label[data-shrink=true] {
    width: 300px !important;
    overflow: hidden !important; 
    white-space: nowrap !important; 
    text-overflow: ellipsis !important;
}
}

.switching-times__add-new-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: end;
  width: 45px;
  height: 45px;
  background-color: var(--primary-color);
  border-radius: 100%;
  cursor: pointer;

  img {
    height: 35px;
  }
}

.switching-times__collapse {
  display: flex;
  gap: 18px;
  margin-bottom: 9px;

  .switching-times__collapse-trash {
    position: relative;
    width: 41px;
    height: 41px;
    margin-top: 18px;
    cursor: pointer;

    i {
      color: #959ba2;
      font-size: 34px;
      position: absolute;
      right: 5px;
      left: 2.5;
      top: 1.5px;
      border: 0;
    }

    &:hover {
      border-radius: 100%;
      background-color: rgb(255, 63, 63);

      i {
        color: #fff;
      }
    }
  }
}

.collapse-container {
  background-color: #CCE9DE;
  border-radius: 5px;
  width: 320px;
  color: #fff;
}