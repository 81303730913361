@import "../../styles/source";

.circle-chart-wrapper {
  padding: 10px;
  min-height: max(40vh, 400px);
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 4px;
  gap: 10px;
  justify-content: center;
  box-shadow: 0px 3px 15px #00000029;

  .title {
    font-weight: 400;
    color: $title-light-color;
    text-align: center;
    white-space: nowrap;

    @include adaptive-value(15, 27);
  }

  .chart-info {
    width: min-content;
    display: flex;
    justify-content: space-between;
  }
}