.invited-users-wrapper {

  .ReactTable {
    background-color: transparent;
    border: 1px solid rgba(44, 46, 53, 0.10);

    .btn.m-btn--hover-accent.trash {
      &:hover,
      &:focus,
      &:active {
        border-color: #ff1c3c !important;
        background-color: #ff1c3c !important;
        i:before {
          color: #fff;
        }
      }

      i:before {
        color: #999999;
      }
    }
  }

  .invited-users-header-container {
    position: absolute;
    right: 15px;
    top: -50px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
