.terms-condition-block {
  margin-bottom: -20px !important;
  height: 30px !important;
  color: red;
  .m-checkbox.m-checkbox--light.checkbox-terms a {
    color: var(--primary-color) !important;
    &:hover {
      border-bottom: 1px solid var(--primary-color);
    }
  }
}
