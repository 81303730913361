.delete-device-preloader-container {
    height: 281px;
    display: flex;
    align-items: center;
}

.flex-multiple-select {
    min-height: 57px;
  }
  
  .guard-footer-modal {
    padding-top: 20px !important;
  }
