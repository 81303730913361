.input-field.ip-field-container {
  &.askoheat-bottom {
    margin-bottom: 50px !important;
  }

  .ip-field {
    width: 100%;
  }

  .identify-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 20px;

    .identify-btn {
      margin-bottom: 20px !important;
      min-width: 109px;
      height: 23px;
      line-height: 0.1 !important;
    }
  }
}
.ocppCharderField,
.skiField {
  position: relative;

  .searchChargersBtn {
    position: absolute;
    z-index: 5;
    top: 18px;
    right: 30px;
    max-height: 25px;
  }
}

.skiField {
  .searchChargersBtn {
    right: 0;
  }
}
