.display-none {
  display: none !important;
}

span.thank_you_email {
  color: var(--primary-color);
  cursor: pointer;
}

.thank_you_email-content {
  min-width: 100%;
}

.thank_you_email-container {
  .btn-upd-dashb {
    margin-bottom: 5%;
  }
}

.thank-you-wrapper {
  background-color: hsla(0deg, 0%, 100%, 0.9);
  flex-direction: column;
  max-height: 500px;
  gap: 60px;
  margin-top: 50px;
  max-width: 800px !important;
}

.logo-wrapper {
  margin-bottom: 50px;
}

.btn-upd-dashb-wrapper {
  margin-bottom: 0px !important;
}