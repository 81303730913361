.pvinstaller-preloader-container {
    height: 245px;
    display: flex;
    align-items: center;
}

.pvinstaller-delete-preload-container {
    height: 158px;
    display: flex;
    align-items: center;
}