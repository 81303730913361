.support-preloader-container {
    height: 168px;
    display: flex;
    align-items: center;
}

.support-delete-preload-container {
    height: 185px;
    display: flex;
    align-items: center;
}