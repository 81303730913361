.viewer-preloader-container {
    height: 168px;
    display: flex;
    align-items: center;
}

.viewer-delete-preload-container {
    height: 185px;
    display: flex;
    align-items: center;
}