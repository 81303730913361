.notFoundPage {
  .errorCode {
    font-size: 10rem;
    line-height: 20rem;
    color: rgba(0, 146, 91, .9);
    font-family: 'Roboto', sans-serif !important;
  }
  .errorHeader {
    font-size: 5rem;
    line-height: 2rem;
    font-family: 'Roboto', sans-serif !important;
  }
  a {
    font-size: 2rem;
    font-family: 'Roboto', sans-serif !important;
  }
}
