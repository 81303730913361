@keyframes showNoAccessPaymentsWarning {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

.center-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.notification-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
  max-width: 100%;
  box-sizing: border-box;
  padding: 25px;
}

.no-access-payments-warning {
  animation-name: showNoAccessPaymentsWarning;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-delay: 1s;
  animation-fill-mode: both;
}

.notification-message-title {
  margin-bottom: 50px;
}

.notification-message-subtitle {
  opacity: 0.5;
  font-size: 12px;
  color: #fff;
  margin-top: 5px;
  text-align: center;
}