.documentation-wrapper {
  .documentation-header-container {
    margin: 50px 0 10px;
    color: #161b2b;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .documentation-header {
    display: flex;
    gap: 10px;

    .documentation-header-title {
      font-size: 18px;
      color: #161b2b;
      margin: 0;
    }

    .documentation-header-subtitle {
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      color: var(--primary-color);
      background-color: #cee4dc;
      padding: 2px 10px;
      border-radius: 10rem;
    }
  }
  .btn.m-btn--pill.m-btn--air.btn-secondary.btn-table-inst.btn-comp.exportBtn.delete-btn {
    color: #f53920 !important;
    &:hover {
      color: #fff !important;
    }
  }
  .delete-btn {
    border: 1px solid #f53920 !important;
    font-weight: 400;
    font-size: 14px !important;
    box-shadow: none !important;
    text-transform: capitalize;
    margin-right: 0 !important;

    &:hover {
      background-color: #f53920 !important;
    }
  }

  .hidden {
    opacity: 0;
    pointer-events: none;
  }

  .documentation-header-selected-items {
    color: #bfbfbf;
    font-size: 14px;
    animation: 1s linear opacity;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      margin-right: 15px;
      color: #bfbfbf;
    }
    .close-btn {
      svg {
        fill: #bfbfbf;
      }

      &:hover {
        svg {
          fill: #bfbfbf;
        }
      }
    }

    .count {
      padding-top: 1px;
      color: #161b2b;
      margin: 0;

      span:nth-child(2) {
        opacity: 0.5;
      }
    }

    .exportBtn {
      height: 38px !important;
      font-size: 14px !important;
    }
  }

  .upload-files-btn {
    padding: 0 15px !important;
    height: 38px;
  }
}
