.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-title {
  font-size: 1.5em;
}

.thank-you-container {
  padding: 65px 45px 60px;
  min-width: 800px;
  background: rgba(255, 255, 255, .9);
  margin: auto;
  color: #161B2B;
  max-height: 500px;
}

.signup-title {
  color: var(--primary-color) !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.sub-user-photo {
  max-width: 300px;
}

:-webkit-autofill {
  transition-delay: 9999s
}
