/* Modal container */

.calendarModal {
  .myModalWindow {
    .modalContainer {
      width: auto;
      min-width: auto;

      .modal-dialog {
        min-width: 545px;
        border-radius: 9px;

        .modal-content {
          border-radius: inherit;

          .customCalendar {
            border-radius: inherit;

            .react-calendar__navigation__prev2-button {
              border-radius: 5px 0 0 0;
            }

            .react-calendar__navigation__next2-button {
              border-radius: 0 5px 0 0;
            }
          }
        }
      }

      .modal-body {
        padding: 0;
        background: none;
        min-width: 543px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
      }
    }
  }
}