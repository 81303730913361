.employee-add-prealoder-container {
    height: 168px;
    display: flex;
    align-items: center;
}

.employee-delete-prealoder-container {
    height: 158px;
    display: flex;
    align-items: center;
}

.user-add-prealoder-container {
    height: 245px;
    display: flex;
    align-items: center;
}

.pvInstallers-title {
    color: rgba(0, 0, 0, .5);
    text-transform: uppercase;
    font-size: 1.6rem;
    margin: 10px 0;
    font-family: 'Roboto', sans-serif;
}

.modal-body.modal-add-installer-to-oem {
    overflow-y: auto;
}

.modal-content .modal-footer.modal-add-installer-to-oem {
    justify-content: space-between;
    align-items: center;
}

.checkbox-row {
    padding: 12px 0 !important;
    margin: 0 5px;
    border-bottom: 2px solid rgba(0, 146, 91, 0.1);
}

.checkbox-row:last-child {
    border-bottom: 0px;
}

.checkbox-row label {
    color: #161B2B !important;
    margin-bottom: 0 !important;
}

.checkbox-row span {
    border: none !important;
}

.checkbox-row .m-checkbox>span {
    background-color: transparent;
    border: 1px solid #767676 !important;
}

.checkbox-row .m-checkbox.m-checkbox--state-warning>span:after {
    border: solid #fff;
}


.checkbox-row .m-checkbox>input:checked~span,
.m-radio>input:checked~span {
    background-color: var(--primary-color);
}

.modal-add-installer-to-oem .btn-popup-sav {
    width: 100px;
}

.modal-add-installer-to-oem .modal-selected {
    margin-right: 1rem;
}

.modal-selected {
    color: #8F939B;
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
}

.no-installer {
    color: #8F939B;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
}

.installer-to-oem-btn {
    width: auto !important;
}

.table-details-user-info-value-padding {
    padding: 0 0 0 25px;
}

.email-text-td-additional-styles {
    letter-spacing: 0.48px;
    color: #68b0ee !important;
    cursor: pointer;
    font-size: 16px !important;
    font-weight: 400px !important;
}

.m-badge--wide-additional-styles {
    border: none;
    font-size: 15px;
    margin-left: 15px;
}

.pv-inst-detail-wrapper {
    .OEMAddBtn {
        margin-top: -109px;
    }
}
