@font-face {
  font-family: LineAwesomeOld, sans-serif;
  src: url(fonts/line-awesome/line-awesome.woff?v=1.1.) format("woff");
  font-weight: 400;
  font-style: normal;
}

.la {
  font-size: inherit;
}

.la.la-pencil-square {
  font-family: LineAwesomeOld, sans-serif;
  font-weight: 400;

  &::before {
    content: "\f2b1";
  }
}