.modal-window-v2 {
  .modal-body-v2 {
    max-height: 100vh;
    overflow-y: auto;
    display: flex;

    background-color: #2B2D34;

    &.permission-deny {
      color: #FFF;

      .modal-title-v2 {
        color: #FFB822;
        font-size: 36px;
      }

      .modal-subtitle-v2 {
        line-height: 28px;
        font-size: 18px;
      }

      .modal-content-v2 {
        line-height: 28px;
        font-size: 16px;
        font-weight: 400;


        &.sub-content {
          opacity: 0.8;
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
}